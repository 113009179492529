export default [
  {
    _name: "TSidebarNavItem",
    name: "Dashboard",
    to: "/purchase/dashboard",
    icon: "cil-speedometer",
  },
  {
    _name: "TSidebarNavTitle",
    content: "Purchase",
  },
  {
    _name: "TSidebarNavItem",
    name: "Purchases",
    to: "/purchase/purchases",
    icon: "cil-library-books",
  },
  {
    _name: "TSidebarNavItem",
    name: "List to buy",
    to: "/purchase/list-to-buy",
    icon: "cil-library-books",
  },
  {
    _name: "TSidebarNavItem",
    name: "Available for distribution",
    to: "/purchase/available-for-distribution",
    icon: "cil-library-books",
  },
  {
    _name: "TSidebarNavTitle",
    content: "Statistics",
  },
  {
    _name: "TSidebarNavItem",
    name: "Purchase inventory",
    to: "/purchase/purchase-inventory",
    icon: "cil-library-books",
  },
  {
    _name: "TSidebarNavItem",
    name: "Goods inventory",
    to: "/purchase/goods-inventory",
    icon: "cil-library-bookmark",
  },
  {
    _name: "TSidebarNavItem",
    name: 'Supplier debt',
    to: "/purchase/supplier-debt",
    icon: "cil-money",
  },
  {
    _name: "TSidebarNavTitle",
    content: "Management",
  },
  {
    _name: "TSidebarNavItem",
    name: "Supplier",
    to: "/purchase/suppliers",
    icon: "cil-store",
  },
  {
    _name: "TSidebarNavItem",
    name: "Tracking",
    to: "/purchase/tracking",
    icon: "cil-cursor",
  },
  {
    _name: "TSidebarNavTitle",
    content: "Goods",
  },
  {
    _name: "TSidebarNavItem",
    name: "Product",
    to: "/purchase/goods/products",
    icon: "cil-chart-table",
  },
  {
    _name: "TSidebarNavItem",
    name: "Category",
    to: "/purchase/goods/categories",
    icon: "cil-view-list",
  },
  {
    _name: "TSidebarNavItem",
    name: "Origin",
    to: "/purchase/goods/origins",
    icon: "cil-toll",
  },
  {
    _name: "TSidebarNavTitle",
    content: "Support",
  },
  {
    _name: "TSidebarNavItem",
    name: "Support",
    to: "/purchase/supports",
    icon: "cil-comment-bubble-question",
  },
]
