import Resource from '@/core/store/Resource';
import tomoni from "@/core/services/tomoni";

const resource = new Resource(
  {
    service: 'order',
    resource: 'purchases',
    prefix: 'order.order_purchase',
    prefix_state: 'order_purchase',
  },
  {
    default_query: {
      include: "itemsCount,trackings,supplier,buyer",
      append: "first_item",
      sort: "-created_at",
    },
    detail_query: {
      append: "transactions",
      include: "trackings,buyer,supplier"
    },
    default_detail: {
      id: "",
      supplier_id: null,
      buyer_id: null,
      additional_cost: 0,
      created_at: null,
      updated_at: null,
      balance: 0,
      items: [],
      receipts: [],
      trackings: [],
    }
  },
).store()

const PREFIX = "order.order_purchase";

const actions = {
  [PREFIX + ".detail.attach-tracking"](context, attributes) {
    return new Promise((resolve, reject) => {
      tomoni.order.purchases
        .attachTracking(context.getters[PREFIX + ".detail.id"], attributes)
        .then(({ data }) => {
          context.commit(PREFIX + '.detail.merge', data)
          context.commit("toasts.push", {
            message: "Updated",
            type: "success",
          });
          resolve(data);
        })
        .catch((error) => {
          context.dispatch("errors.push-http-error", error);
          reject(error);
        });
    });
  },
  [PREFIX + ".detail.remove-tracking"](context, attributes) {
    return new Promise((resolve, reject) => {
      tomoni.order.purchases
        .detachTracking(context.getters[PREFIX + ".detail.id"], attributes)
        .then(({ data }) => {
          context.commit(PREFIX + '.detail.merge', data)
          context.commit("toasts.push", {
            message: "Remove",
            type: "danger",
          });
          resolve(data);
        })
        .catch((error) => {
          context.dispatch("errors.push-http-error", error);
          reject(error);
        });
    });
  },
};

export default {
  ...resource,
  actions: { ...resource.actions, ...actions },
};

