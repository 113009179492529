import Resource from '@/core/store/Resource';

const resource = new Resource(
  {
    service: 'order',
    resource: 'order_items',
    prefix: 'order.order_items_need_buy',
    prefix_state: 'order_items_need_buy',
  },
  {
    default_query: {
      include: "order,customer",
      append: "currency_id",
      "filter[available_for_distribution]": 1
    },
    default_detail: {
      id: null,
      supplier_id: null,
      buyer_id: null,
      additional_cost: 0,
      created_at: null,
      updated_at: null,
      balance: 0,
      order: {
        customer_id: ""
      },
      receipts: [],
    }
  },
).store()

export default resource
