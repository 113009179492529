import order_purchase from './order/order_purchase'
import purchase_receipts from './order/purchase_receipts'
import order_items_need_buy from './order/order_items_need_buy'
import purchase_suppliers from './product/purchase_suppliers'
import purchase_product_statistics from './product/purchase_product_statistics'

export default {
  order_purchase,
  purchase_receipts,
  order_items_need_buy,
  purchase_suppliers,
  purchase_product_statistics
}
