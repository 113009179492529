import Resource from '@/core/store/Resource';

const resource = new Resource(
  {
    service: 'product',
    resource: 'product_statistics',
    prefix: 'product.purchase_product_statistics',
    prefix_state: 'product_purchase_product_statistic',
  },
  {
    default_query: {
      include: "product,suppliers",
      "filter_between[output]": "0,infinity",
    },
  }
).store()

export default resource
