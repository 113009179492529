import Resource from '@/core/store/Resource';

const resource = new Resource(
  {
    service: 'product',
    resource: 'suppliers',
    prefix: 'product.purchase_suppliers',
    prefix_state: 'product_purchase_suppliers',
  },
).store()

export default resource
